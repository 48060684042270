import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Regular from '../components/Regular'
import Hotel from '../components/Hotel'
import Footer from '../components/Footer'
import axios from 'axios';
import Loader from '../components/Loader'
import { Audio, DNA } from 'react-loader-spinner'
import NotFoundComponent from '../components/NotFoundComponent'
function Home() {

const [data, setData] = useState(null);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(false);
const iframeStyles = {
  position: "absolute",
  top: "0",
  right: "0",
  height: "100%",
  width: "100%",
  border: "none",
  transition: "opacity 0.5s ease-in-out",
  opacity: loading ? 0 : 1 
};
const [trackingData, setTrackingData] = useState({
  gclid: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_term: null,
});

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const gclid = queryParams.get('gclid');
    const utm_source = queryParams.get('utm_source');
    const utm_medium = queryParams.get('utm_medium');
    const utm_campaign = queryParams.get('utm_campaign');
    const utm_term = queryParams.get('utm_term');




  if(gclid){
    axios.post('https://api.top10tilbud.com/api/Hotels',{
      gclid: gclid,
      utmSource: utm_source,
      utmMedium: utm_medium,
      utmCampaign: utm_campaign,
      utmTerm: utm_term
    })
    .then(response => {
      setData(response.data);
      setLoading(false); 
      setError(response.status===207);

    })
    .catch(error => {
      console.error('API çağrısı sırasında bir hata oluştu:', error);
      setLoading(false); 
    });
  }else{
    setLoading(false)

  }
  }, []);

  if (loading) 
  return (
    <div style={{ margin: "0px", padding: "0px", height: "100%", overflow: 'hidden' }}>
      <DNA
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{ position: "absolute", top: "0", right: "0", height: "100%", width: "100%", border: "none" }}
        wrapperClass="dna-wrapper"
      />
    </div>
  );

if (error)
  return (
    <div style={{ margin: "0px", padding: "0px", height: "100%", overflow: 'hidden' }}>
     <DNA
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{ position: "absolute", top: "0", right: "0", height: "100%", width: "100%", border: "none" }}
        wrapperClass="dna-wrapper"
      />
      <iframe style={iframeStyles} src={data} title="Full Page iFrame"></iframe>
    </div>
  );
  return (
    <>
      <Header />
      <Regular content={data} />
      <Hotel content={data} />
      <Footer />
    </>
  );
}

export default Home
import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src="/Logo.webp" alt="logo" />
            <p>
              <b>Kontaktinformasjon:</b>
              <br />
              top10tilbud AS
              <br />
              Fv161 26, 16, 0191 Oslo, Norge
              <br />
              <a href="tel:+4718372942">+47 184 77 941</a>
              <br />
              <a href="mailto:info@top10tilbud.com">info@top10tilbud.com</a>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <b>Opplysningspliktig:</b>
              <br />
              Dette nettstedet er kun ment for informasjonsformål og er ikke ansvarlig for noen salgsfremmende informasjon på nettstedet, inkludert forskning, prognoser, estimater knyttet til finansielle instrumenter eller nøyaktigheten av informasjonen i annonser. Den redaksjonelle teksten inneholder ingen bakgrunnsinformasjon.
            </p>
          </div>
          <div className="col-md-3">
            <ul>
              <li><a href="/privacy">Privacybeleid</a></li>
              <li><a href="/termsandconditions">Algemene voorwaarden</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/cookie">Cookie</a></li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          Alle rettigheter forbeholdes. {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
